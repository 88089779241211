/*Colors*/
@media(prefers-color-scheme: dark){
	body {
		background: #2f383e;
	}
	div.menu-bar {
		background: #2f383e;
	}
	h1,h2,h3,h4,h5,h6 {
		color: #00dd9f;
	}
	div#MainContentWrapper {
		border-color: #2f383e;
	}
	div.main-content {
		color: #eee;
	}
	article.main-content-body {
		background: #1b252b;
	}
	div.menu-tag-2 {
		background: #1b2022;
		color: #00f3ff;
	}

	div.menu-tag-1 {
		background: #1b2022;
		color: #00f3ff;
	}
	a {
		color: #97ff00;
	}
	a.menu-item {
		color: #fff;
	}
	a.menu-item:hover {
		color: #97ff00;
	}
	h1::selection,h2::selection,h3::selection,h4::selection,h5::selection,h6::selection{
		color: #fff;
	}
	h1::-moz-selection,h2::-moz-selection,h3::-moz-selection,h4::-moz-selection,h5::-moz-selection,h6::-moz-selection{
		color: #fff;
	}
	::-moz-selection {
		background-color: #511c1e;
	}	
	::selection {
		background-color: #511c1e;
	}
	a::-moz-selection {
		background-color: #628f00;
		color: #fff;
	}	
	a::selection {
		background-color: #628f00;
		color: #fff;
	}

	table:not(.codehilitetable) {
		background: #2f383e;
		color: #eee;
	}
	table:not(.codehilitetable) th {
		color: #eee;
	}
	table:not(.codehilitetable) tbody tr:nth-child(even) { 
		background-color: #222426;
	}
	table:not(.codehilitetable) tbody tr:nth-child(odd) { 
		background-color: #2d3133;
	}

	table.codehilitetable {
		color: #eee;
	}
	:not(pre) > code {
		background: #32424a;
		color: #fff;
	}
}

@media(prefers-color-scheme: light){
	body {
		background: #929299;
	}
	div.menu-bar {
		background: #929299;
	}
	h1,h2,h3,h4,h5,h6 {
		color: #e00;
	}
	div#MainContentWrapper {
		border-color: #929299;
	}
	div.main-content {
		color: #000;
	}
	article.main-content-body {
		background: #eee;
		border-color: #929299;
	}
	div.menu-tag-2 {
		background: #353a3b;
		color: #0f0;
	}
	div.menu-tag-1 {
		background: #353a3b;
		color: #0f0;
	}
	div.menu-tag-2 a{
		color: #ff0;
	}
	div.menu-tag-1 a{
		color: #ff0;
	}
	a {
		color: #d00;
	}
	a.menu-item {
		color: #000;
	}
	a.menu-item:hover {
		color: #eee;
	}
	::-moz-selection {
		background-color: #d00;
		color: #fff;
	}	
	::selection {
		background-color: #d00;
		color: #fff;
	}
	a::-moz-selection {
		background-color: #000;
		color: #fff;
	}	
	a::selection {
		background-color: #000;
		color: #fff;
	}

	table:not(.codehilitetable) {
		background: #929299;
	}
	table:not(.codehilitetable) th {
		color: #fff;
	}
	table:not(.codehilitetable) tbody tr:nth-child(even) { 
		background-color: #eee;
	}
	table:not(.codehilitetable) tbody tr:nth-child(odd) { 
		background-color: #dadada;
	}
	:not(pre) > code {
		background: #d6d0d0;
	}
}
/*******************************************/

.indent-1 { margin-left: 1em;}
.indent-2 { margin-left: 2em;}
.indent-3 { margin-left: 3em;}
.indent-4 { margin-left: 4em;}
.indent-5 { margin-left: 5em;}
.indent-6 { margin-left: 6em;}
.indent-7 { margin-left: 7em;}
.indent-8 { margin-left: 8em;}
.indent-9 { margin-left: 9em;}

body {
	font-family: courier;
	font-size: 16px;
	line-height: 1.5;
	margin: 0;
	padding: 0;
	padding-bottom: 3em;
}

td.linenos {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

:not(pre) > code {
	padding: 1pt;
	border-radius: 5pt;
	font-size: 14px;
}

h1 {
	padding-bottom: 0;
	margin-bottom: 0;
}

p.copyright {
	padding: 0;
	margin: 0;
	padding-top: 1em;
	font-size: 0.8em;
	opacity: 0.7;
}

div.series-nav {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

h1.index-item {
	border-bottom: 1px solid #d00;
}

a.index-item-series {
	
}
ul.index-item-series {
	list-style-type: disc;
}

ul.index-item {
	list-style-type: none;
	line-height: 1.3;
}

li.index-item {
	margin-top: 0.25em;
}

li.index-item i {
	font-size: 0.8em;
	opacity: 0.7;
}

p.article-date {
	padding: 0;
	margin: 0;
	padding-top: 0;
	font-size: 0.8em;
	opacity: 0.7;
}

div.main-content {
	font-family: helvetica;
}

div#MainContentWrapper {
	border-width: 4px;
	border-style: solid;
	border-radius: 20pt;
	padding: 0.5em 2em;
	height: 100%;
}

article.main-content-body {
	border: none;
	padding: 4px;
	border-radius: 20pt;
	margin: 4px;
	flex-grow: 2;
	
	display:flex;
	flex-direction:column;
}

article.main-content-body img {
	margin-top: 1em;
	margin-bottom: 1em;
	max-width: 100%;
	display: block;
	border-radius: 5pt;
}

video {
	margin-top: 1em;
	margin-bottom: 1em;
	max-width: 100%;
	display: block;
	border-radius: 5pt;
}

iframe {
	margin-top: 1em;
	margin-bottom: 1em;
	max-width: 100%;
	display: block;
	border-radius: 5pt;
}

p.caption {
	opacity: 0.75;
	text-align: center;
	display: block;
	width: 100%;
}

div.menu-tag-2 {
	margin: 0;
	display: flex;
	justify-content: center;
	font-size: 0.85em;
}

div.menu-tag-1 {
	margin: 0;
	display: flex;
	justify-content: center;
	font-size: 0.85em;
	width: 100%;
	padding-top: 2pt;
	padding-bottom: 2pt;
}

table:not(.codehilitetable) {
	padding: 2pt;
	border-radius: 5pt;
}
td, tr, th{
	margin: 0;
	padding: 4pt;
	text-align: left;
}

a {
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}

a.menu-item {
	text-decoration: none;
	padding: 5pt;
	padding-left: 10pt;
}

a.menu-item:hover {
	text-decoration: underline;
}

div.codehilite, table.codehilitetable {
	border-radius: 15pt;
	padding-left: 5pt;
	padding-right: 5pt;
	overflow-x: scroll;
}

table.codehilitetable {
	padding: 0;
	margin: 0;
}

table.codehilitetable tr{
	padding: 0;
	margin: 0;
}

table.codehilitetable td{
	padding: 0;
	margin: 0;
}

div.tag-list {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 12px;
	font-family: monospace;
	opacity: 0.7;
}

div.tag-list a {
	padding-right: 10px;
	text-decoration: underline;
}
div.tag-list a:hover {
	text-decoration: none;
}
summary {
	cursor: pointer;
}

@media (orientation: landscape) {

	body {
		margin: 0px;
		display: flex;
		flex-direction: column;
	}

	div.body-wrapper {
		padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
		margin-top: 1em;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	div.main-content {
		padding-left: 10pt;
		padding-right: 10pt;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	article.main-content-body {
		width: 800px;
	}

	div.menu-bar {
		margin: 0px;
		padding: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	div.menu-tag-2 {
		margin: 0;
		display: flex;
		justify-content: center;
		padding: 2pt;
	}

	div.menu-tag-1 {
		display: none;
	}

	.menu-item {
		height: 26pt;
		vertical-align: middle;
		text-align: center;
		display: flex;
		align-items: center;
	}

}

@media (orientation: portrait) {
	body {
		display: flex;
		flex-direction: column;
	}
	
	div.body-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	div.main-content {
		margin-top: 1em;
		width: 800px;
		max-width: 100%;
	}

	div.menu-tag-2 {
		display: none;
	}

	div.menu-bar {
		width: 100%;
		margin: 0px;
		padding: 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	div.menu-bar-body {
		width: 60%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.menu-item {
		height: 2.4em;
		vertical-align: middle;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}

}