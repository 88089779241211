@media (prefers-color-scheme: dark) {
  body, div.menu-bar {
    background: #2f383e;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #00dd9f;
  }

  div#MainContentWrapper {
    border-color: #2f383e;
  }

  div.main-content {
    color: #eee;
  }

  article.main-content-body {
    background: #1b252b;
  }

  div.menu-tag-2, div.menu-tag-1 {
    color: #00f3ff;
    background: #1b2022;
  }

  a {
    color: #97ff00;
  }

  a.menu-item {
    color: #fff;
  }

  a.menu-item:hover {
    color: #97ff00;
  }

  h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection {
    color: #fff;
  }

  h1::-moz-selection {
    color: #fff;
  }

  h2::-moz-selection {
    color: #fff;
  }

  h3::-moz-selection {
    color: #fff;
  }

  h4::-moz-selection {
    color: #fff;
  }

  h5::-moz-selection {
    color: #fff;
  }

  h6::-moz-selection {
    color: #fff;
  }

  ::selection {
    background-color: #511c1e;
  }

  a::selection {
    color: #fff;
    background-color: #628f00;
  }

  table:not(.codehilitetable) {
    color: #eee;
    background: #2f383e;
  }

  table:not(.codehilitetable) th {
    color: #eee;
  }

  table:not(.codehilitetable) tbody tr:nth-child(2n) {
    background-color: #222426;
  }

  table:not(.codehilitetable) tbody tr:nth-child(odd) {
    background-color: #2d3133;
  }

  table.codehilitetable {
    color: #eee;
  }

  :not(pre) > code {
    color: #fff;
    background: #32424a;
  }
}

@media (prefers-color-scheme: light) {
  body, div.menu-bar {
    background: #929299;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #e00;
  }

  div#MainContentWrapper {
    border-color: #929299;
  }

  div.main-content {
    color: #000;
  }

  article.main-content-body {
    background: #eee;
    border-color: #929299;
  }

  div.menu-tag-2, div.menu-tag-1 {
    color: #0f0;
    background: #353a3b;
  }

  div.menu-tag-2 a, div.menu-tag-1 a {
    color: #ff0;
  }

  a {
    color: #d00;
  }

  a.menu-item {
    color: #000;
  }

  a.menu-item:hover {
    color: #eee;
  }

  ::selection {
    color: #fff;
    background-color: #d00;
  }

  a::selection {
    color: #fff;
    background-color: #000;
  }

  table:not(.codehilitetable) {
    background: #929299;
  }

  table:not(.codehilitetable) th {
    color: #fff;
  }

  table:not(.codehilitetable) tbody tr:nth-child(2n) {
    background-color: #eee;
  }

  table:not(.codehilitetable) tbody tr:nth-child(odd) {
    background-color: #dadada;
  }

  :not(pre) > code {
    background: #d6d0d0;
  }
}

.indent-1 {
  margin-left: 1em;
}

.indent-2 {
  margin-left: 2em;
}

.indent-3 {
  margin-left: 3em;
}

.indent-4 {
  margin-left: 4em;
}

.indent-5 {
  margin-left: 5em;
}

.indent-6 {
  margin-left: 6em;
}

.indent-7 {
  margin-left: 7em;
}

.indent-8 {
  margin-left: 8em;
}

.indent-9 {
  margin-left: 9em;
}

body {
  margin: 0;
  padding: 0 0 3em;
  font-family: courier;
  font-size: 16px;
  line-height: 1.5;
}

td.linenos {
  -webkit-user-select: none;
  user-select: none;
}

:not(pre) > code {
  border-radius: 5pt;
  padding: 1pt;
  font-size: 14px;
}

h1 {
  margin-bottom: 0;
  padding-bottom: 0;
}

p.copyright {
  opacity: .7;
  margin: 0;
  padding: 1em 0 0;
  font-size: .8em;
}

div.series-nav {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

h1.index-item {
  border-bottom: 1px solid #d00;
}

ul.index-item-series {
  list-style-type: disc;
}

ul.index-item {
  line-height: 1.3;
  list-style-type: none;
}

li.index-item {
  margin-top: .25em;
}

li.index-item i {
  opacity: .7;
  font-size: .8em;
}

p.article-date {
  opacity: .7;
  margin: 0;
  padding: 0;
  font-size: .8em;
}

div.main-content {
  font-family: helvetica;
}

div#MainContentWrapper {
  border-style: solid;
  border-width: 4px;
  border-radius: 20pt;
  height: 100%;
  padding: .5em 2em;
}

article.main-content-body {
  border: none;
  border-radius: 20pt;
  flex-direction: column;
  flex-grow: 2;
  margin: 4px;
  padding: 4px;
  display: flex;
}

article.main-content-body img, video, iframe {
  border-radius: 5pt;
  max-width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
}

p.caption {
  opacity: .75;
  text-align: center;
  width: 100%;
  display: block;
}

div.menu-tag-2 {
  justify-content: center;
  margin: 0;
  font-size: .85em;
  display: flex;
}

div.menu-tag-1 {
  justify-content: center;
  width: 100%;
  margin: 0;
  padding-top: 2pt;
  padding-bottom: 2pt;
  font-size: .85em;
  display: flex;
}

table:not(.codehilitetable) {
  border-radius: 5pt;
  padding: 2pt;
}

td, tr, th {
  text-align: left;
  margin: 0;
  padding: 4pt;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.menu-item {
  padding: 5pt 5pt 5pt 10pt;
  text-decoration: none;
}

a.menu-item:hover {
  text-decoration: underline;
}

div.codehilite, table.codehilitetable {
  border-radius: 15pt;
  padding-left: 5pt;
  padding-right: 5pt;
  overflow-x: scroll;
}

table.codehilitetable, table.codehilitetable tr, table.codehilitetable td {
  margin: 0;
  padding: 0;
}

div.tag-list {
  opacity: .7;
  flex-flow: wrap;
  align-items: flex-start;
  font-family: monospace;
  font-size: 12px;
  display: flex;
}

div.tag-list a {
  padding-right: 10px;
  text-decoration: underline;
}

div.tag-list a:hover {
  text-decoration: none;
}

summary {
  cursor: pointer;
}

@media (orientation: landscape) {
  body {
    flex-direction: column;
    margin: 0;
    display: flex;
  }

  div.body-wrapper {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
    display: flex;
  }

  div.main-content {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10pt;
    padding-right: 10pt;
    display: flex;
  }

  article.main-content-body {
    width: 800px;
  }

  div.menu-bar {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    display: flex;
  }

  div.menu-tag-2 {
    justify-content: center;
    margin: 0;
    padding: 2pt;
    display: flex;
  }

  div.menu-tag-1 {
    display: none;
  }

  .menu-item {
    vertical-align: middle;
    text-align: center;
    align-items: center;
    height: 26pt;
    display: flex;
  }
}

@media (orientation: portrait) {
  body {
    flex-direction: column;
    display: flex;
  }

  div.body-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
  }

  div.main-content {
    width: 800px;
    max-width: 100%;
    margin-top: 1em;
  }

  div.menu-tag-2 {
    display: none;
  }

  div.menu-bar {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  }

  div.menu-bar-body {
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
    display: flex;
  }

  .menu-item {
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 2.4em;
    display: flex;
  }
}

/*# sourceMappingURL=style.css.map */
